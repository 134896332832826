
import { defineComponent } from 'vue';
import axios from 'axios';
import stringify from 'json-stringify-pretty-compact';

export default defineComponent({
  name: 'sentio-homepage',
  data: () => ({
    error: '',
    message: {
      func: '0x1::coin::balance',
      type_args: '0x1::aptos_coin::AptosCoin',
      args: '0x21ddba785f3ae9c6f03664ab07e9ad83595a0fa5ca556cec2b9d9e7100db0f07',
      ledger_version: 35842267,
      network: 'mainnet',
      with_logs: false,
    },
    isShow: false,
    result: '',
  }),
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || 'Sentio Composer';
      },
    },
    message: {
      handler(newValue) {
        this.$router.replace({
          query: newValue,
        });
      },
      deep: true,
    },
  },
  computed: {
  },
  mounted() {
    const { query } = this.$route;
    if (query.func) {
      this.message = {
        ...query,
        with_logs: query.with_logs === 'true',
      };
    }
  },
  methods: {
    callFunction() {
      console.log(this.message);
      const typeArgs = this.message.type_args.trim();
      const args = this.message.args.trim();
      const network = this.message.network.trim();
      const requestBody = {
        func: this.message.func,
        type_args: typeArgs.length > 0 ? typeArgs.split('\n') : undefined,
        args: args.length > 0 ? args.split('\n') : undefined,
        ledger_version: this.message.ledger_version,
        network: network.length > 0 ? network : undefined,
        options: this.message.with_logs ? { with_logs: true } : undefined,
      };
      axios.post('/api/call_function', requestBody)
        .then((response) => {
          const result = response.data;
          if (result.error) {
            // there was an error...
            const error = 'Failed to call the function, check errors in the result or re-call the function with debug logs enabled';
            this.error = error;
            this.result = stringify(result.details);
          } else {
            this.error = '';
            if (!this.message.with_logs) {
              this.result = stringify(result.details.return_values);
            } else {
              this.result = stringify(result.details);
            }
          }
          this.isShow = true;
        });
    },
    clearInput() {
      this.message = {
        func: '',
        type_args: '',
        args: '',
        ledger_version: 0,
        network: '',
        with_logs: false,
      };
    },
    loadExample(n) {
      const examples = [
        {
          func: '0x1::coin::balance',
          type_args: '0x5a97986a9d031c4567e15b797be516910cfcb4156312482efc6a19c0a30c948::lp_coin::LP<0x1::aptos_coin::AptosCoin, 0x84d7aeef42d38a5ffc3ccef853e1b82e4958659d16a7de736a29c55fbbeb0114::staked_aptos_coin::StakedAptosCoin, 0x190d44266241744264b964a37b8f09863167a12d3e70cda39376cfb4e3561e12::curves::Stable>',
          args: '0xc0cbc38f05f1aed5467b889ef704135bc01f0a492d6fd28ef499d19e7bb58108',
          ledger_version: 0,
          network: 'mainnet',
          with_logs: false,
        }, {
          func: '0xb91d3fef0eeb4e685dc85e739c7d3e2968784945be4424e92e2f86e2418bf271::crank::peak',
          type_args: '',
          args: '0xd08a5107feb5f2df15c913702b0969ae4e22b3653a98c14fcd5e9e00cf8a039d\n2',
          ledger_version: 0,
          network: 'testnet',
          with_logs: false,
        }, {
          func: '0x6de517a18f003625e7fba9b9dc29b310f2e3026bbeb1997b3ada9de1e3cec8d6::opc::totalSupply',
          type_args: '',
          args: '',
          ledger_version: 0,
          network: 'mainnet',
          with_logs: false,
        },
      ];
      if (examples[n]) {
        this.message = examples[n];
      }
    },
  },
});
